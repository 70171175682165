/*eslint-disable*/
import React from "react";
import { useRouter } from 'next/router';

import useSWR from 'swr'
import PropTypes from "prop-types";

import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GridContainer from "/components/Grid/GridContainer.js";
import GridItem from "/components/Grid/GridItem.js";

import styles from "/styles/jss/bbhost/components/footerStyle.js";
import FooterLinkArea from "/components/Footer/FooterLinkArea.js";
import FooterSocialArea from "/components/Footer/FooterSocialArea.js";
import StatusPage from "/components/Functions/StatusPage.js";
import Certifications from "/components/Footer/Certifications.js";
import Button from "/components/CustomButtons/Button.js";

import { 
  PriorityHigh as PriorityHighIcon, 
  Check as CheckIcon, 
  Warning as WarningIcon, 
  ErrorOutline as ErrorOutlineIcon 
} from '@material-ui/icons';

import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(styles);

import { trans } from "lang/lang.js";
import { CONTATO } from "configs/index";

const StatusPageSWRAlertObj = {
  none: {
    text_trans: "footer::status.ok",
    color: "success",
    icon: <CheckIcon />
  },
  ok: {
    text_trans: "footer::status.ok",
    color: "success",
    icon: <CheckIcon />
  },
  minor: {
    text_trans: "footer::status.warning",
    color: "warning",
    icon: <WarningIcon />
  },
  major: {
    text_trans: "footer::status.danger",
    color: "danger",
    icon: <ErrorOutlineIcon />
  },
  critical: {
    text_trans: "footer::status.danger",
    color: "danger",
    icon: <ErrorOutlineIcon />
  },
  maintenance: {
    text_trans: "footer::status.maintenance",
    color: "info",
    icon: <PriorityHighIcon />
  }
};

const fetcher = (url) => fetch(url).then((res) => res.json());

export default function Footer(props) {
  const classes = useStyles();
  const { locale } = useRouter();
  const { whiteFont, blocked } = props;
  // const { data: dataStatusPage } = useSWR("https://bbhost.statuspage.io/api/v2/status.json", fetcher)
  const { data: dataStatusPage } = useSWR("https://api-frontend-site.bbhost.com.br/status-page", fetcher)
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const contatArea = CONTATO.map((contato, key) => {
    return (
      <Button key={contato.name} href={contato.route} simple target="_blank" size="sm" color={contato.color}>
        <FontAwesomeIcon icon={contato.icon} size="lg" /> {contato.text}
      </Button>
    );
  });

  if(blocked)
  {
    return (
      <footer className={footerClasses}>
        <div className={classes.container}>
          <GridContainer justifyContent="center" alignItems="center">
            <GridItem xs={12} sm={12} md={6}>            
              <div>
                <StatusPage locale={locale} />
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <div>
                {trans('footer::copy')} &copy; 2013 - {1900 + new Date().getYear()}
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </footer>
    );
  }

  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <GridContainer justifyContent="center">
          <Certifications locale={locale} />
          <FooterLinkArea locale={locale} />
          <FooterSocialArea locale={locale} />
          <GridItem xs={12} sm={12} md={4}>
            <h5>{trans('footer::contato.title')}</h5>
            {contatArea}
          </GridItem>
        </GridContainer>
        <GridContainer justifyContent="center" alignItems="center">
          <GridItem xs={12} sm={12} md={6}>            
            <div>
              {dataStatusPage?
                <Button target="_blank" href="https://status.bbhost.com.br" color={StatusPageSWRAlertObj[dataStatusPage.status.indicator].color} simple>
                  {StatusPageSWRAlertObj[dataStatusPage.status.indicator].icon} {trans(StatusPageSWRAlertObj[dataStatusPage.status.indicator].text_trans)}
                </Button>
                :
                <Button target="_blank" href="https://status.bbhost.com.br" color="success" simple>Carregando informação da Status Page</Button>
              }
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <div>
              {trans('footer::copy')} &copy; 2013 - {1900 + new Date().getYear()}
            </div>
          </GridItem>
        </GridContainer>
        <GridContainer justifyContent="center" alignItems="center">
          <GridItem xs={12} sm={12} md={12}>
            <Typography variant="subtitle2">
              BB Host LTDA - CNPJ 27.041.737/0001-09
            </Typography>
          </GridItem>
        </GridContainer>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
  blocked: PropTypes.bool
};
